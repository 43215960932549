<template>
  <div v-loading="loadingTwo" element-loading-background="rgba(0, 0, 0, 0.8)">
    <h2 class="leading--two">Set Card PIN</h2>

    <form class="form" @submit.prevent="submitForm" novalidate="true">
      <div class="form__group">
        <label for="pin">
          <input
            id="pin"
            :type="passwordFieldTypeThree"
            name="pin"
            class="form__input"
            placeholder="Set New PIN"
            v-model="$v.form.pin.$model"
            :class="{ 'is-invalid': submitted && $v.form.pin.$error }"
        /></label>
        <span @click="switchVisibilityThree" class="form__password">{{
          passwordFieldTypeThree === "password" ? "Show" : "Hide"
        }}</span>
        <span v-if="submitted && $v.form.pin.$error" class="error">
          <small class="error" v-if="!$v.form.pin.required"
            >Pin is required</small
          >
          <!-- <br /> -->
          <small class="error" v-if="!$v.form.pin.numeric"
            >Needs to be numeric only.</small
          >
          <!-- <br /> -->
          <small
            class="error"
            v-if="!$v.form.pin.maxLength || !$v.form.pin.minLength"
            >Pin must be 4 numbers.</small
          >
        </span>
      </div>
      <div class="form__group">
        <label for="pinConfirm">
          <input
            id="pinConfirm"
            :type="passwordFieldTypeFour"
            name="pinConfirm"
            class="form__input"
            placeholder="Confirm New PIN"
            v-model="$v.form.pinConfirm.$model"
            :class="{
              'is-invalid': submitted && $v.form.pinConfirm.$error
            }"
        /></label>
        <span @click="switchVisibilityFour" class="form__password">{{
          passwordFieldTypeFour === "password" ? "Show" : "Hide"
        }}</span>
        <span v-if="submitted && $v.form.pinConfirm.$error" class="error">
          <small class="error" v-if="!$v.form.pinConfirm.required"
            >Confirm pin is required</small
          >
          <!-- <br /> -->
          <small class="error" v-if="!$v.form.pinConfirm.sameAsPin"
            >Pins do not match.</small
          >
          <!-- <br /> -->
          <small class="error" v-if="!$v.form.pinConfirm.numeric"
            >Needs to be numeric only.</small
          >
          <!-- <br /> -->
          <small
            class="error"
            v-if="!$v.form.pin.maxLength || !$v.form.pin.minLength"
            >Pin must be 4 numbers.</small
          >
        </span>
      </div>

      <button type="submit" class="btn btn__red btn__group">
        <loading v-if="loading" />
        <span>Set Pin</span>
      </button>
    </form>
  </div>
</template>

<script>
import api from "@/api/api.js";
import { mapState } from "vuex";
import {
  required,
  sameAs,
  numeric,
  minLength,
  maxLength
} from "vuelidate/lib/validators";
export default {
  computed: mapState(["userDetailsTwo", "user", "cardDetail"]),
  data() {
    return {
      passwordFieldTypeThree: "password",
      passwordFieldTypeFour: "password",
      passwordFieldType: "password",
      loading: false,
      loadingTwo: false,
      submitted: false,
      form: {
        pin: "",
        pinConfirm: ""
      }
    };
  },
  validations: {
    form: {
      pin: {
        required,
        numeric,
        minLength: minLength(4),
        maxLength: maxLength(4)
      },
      pinConfirm: {
        required,
        numeric,
        minLength: minLength(4),
        maxLength: maxLength(4),
        sameAsPin: sameAs("pin")
      }
    }
  },
  methods: {
    switchVisibilityThree() {
      this.passwordFieldTypeThree =
        this.passwordFieldTypeThree === "password" ? "text" : "password";
    },
    switchVisibilityFour() {
      this.passwordFieldTypeFour =
        this.passwordFieldTypeFour === "password" ? "text" : "password";
    },
    submitForm() {
      this.submitted = true;

      this.$v.form.$touch();

      if (this.$v.form.$invalid) {
        return;
      }

      let payload = {
        PAN: this.cardDetail.pan,
        ExpiryDate: this.cardDetail.exp,
        PIN: this.form.pin
      };

      this.loading = true;

      api
        .setCardPin(payload)
        .then(response => {
          this.loading = false;

          let responseStatus = response.Status;
          let responseMessage = response.Message;

          if (responseStatus) {
            this.$message({
              showClose: true,
              message: `${responseMessage.charAt(0).toUpperCase() +
                responseMessage.slice(1)}`,
              type: "success",
              duration: 10000
            });
            this.$emit("switchRightComponent");
          } else {
            this.$message({
              showClose: true,
              message: `${responseMessage.charAt(0).toUpperCase() +
                responseMessage.slice(1)}`,
              type: "error",
              duration: 10000
            });
          }
        })
        .catch(error => {
          this.loading = false;
          this.$message({
            showClose: true,
            message: `${error}`,
            type: "error",
            duration: 10000
          });
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_form.scss";
</style>
