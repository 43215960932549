<template>
  <div v-loading="loadingTwo" element-loading-background="rgba(0, 0, 0, 0.8)">
    <div>
      <h2 class="leading--two">Card Limit</h2>
      <p class="leading--two">You want to set limit for?</p>
    </div>

    <div class="btn__groups btn__groups--spaced">
      <button
        class="btn btn__transparent-red"
        @click="setPurchaseLimit"
        v-if="bothLimits && cardPurchaseLimit"
      >
        Card Purchase
      </button>
      <button
        class="btn btn__transparent-red"
        @click="setTransferLimit"
        v-if="bothLimits && cardTransferLimit"
      >
        Card Transfer
      </button>
    </div>

    <form class="form" @submit.prevent="submitForm" novalidate="true" v-if="showAmount">
      <div class="form__group--three">
        <label for="amount" class="form__label">
          <input
            type="tel"
            id="amount"
            name="amount"
            placeholder="Enter Amount"
            class="form__input"
            autocorrect="off"
            spellcheck="false"
            autocomplete="off"
            v-model.lazy="$v.purchaseLimit.$model"
            :class="{
              'is-invalid': submitted && $v.purchaseLimit.$error,
            }"
        /></label>
        <small class="error" v-if="submitted && !$v.purchaseLimit.required"
          >Amount is required</small
        >
        <br />
        <small class="error" v-if="submitted && !$v.purchaseLimit.numeric"
          >Needs to be numeric only.</small
        >
      </div>

      <!-- <button type="submit" class="btn btn__red btn__group">
        <loading v-if="loading" />
        <span>Proceed</span>
      </button> -->

      <div class="btn__grid-two">
        <button type="submit" class="btn btn__red btn__group">
          <loading v-if="loading" />
          <span>Proceed</span>
        </button>
        <button class="btn btn__black" @click="changeComponent">Cancel</button>
      </div>
    </form>

    <form
      class="form"
      @submit.prevent="submitFormTwo"
      novalidate="true"
      v-if="showAmountTwo"
    >
      <div class="form__group--three">
        <label for="amountTwo" class="form__label">
          <input
            type="tel"
            id="amountTwo"
            name="amountTwo"
            placeholder="Enter Amount"
            class="form__input"
            autocorrect="off"
            spellcheck="false"
            autocomplete="off"
            v-model.lazy="$v.transferLimit.$model"
            :class="{
              'is-invalid': submitted && $v.transferLimit.$error,
            }"
        /></label>
        <small class="error" v-if="submitted && !$v.transferLimit.required"
          >Amount is required</small
        >
        <br />
        <small class="error" v-if="submitted && !$v.transferLimit.numeric"
          >Needs to be numeric only.</small
        >
      </div>

      <!-- <button type="submit" class="btn btn__red btn__group">
        <loading v-if="loading" />
        <span>Proceed</span>
      </button> -->

      <div class="btn__grid-two">
        <button type="submit" class="btn btn__red btn__group">
          <loading v-if="loading" />
          <span>Proceed</span>
        </button>
        <button class="btn btn__black" @click="changeComponent">Cancel</button>
      </div>
    </form>
  </div>
</template>

<script>
import api from "@/api/api.js";
import { mapState } from "vuex";
import { required, numeric } from "vuelidate/lib/validators";
export default {
  computed: mapState(["userDetailsTwo", "user", "cardDetail"]),
  data() {
    return {
      loading: false,
      loadingTwo: false,
      submitted: false,
      purchaseLimit: "",
      transferLimit: "",
      showAmount: false,
      showAmountTwo: false,
      cardPurchaseLimit: true,
      cardTransferLimit: true,
      bothLimits: true,
    };
  },
  validations: {
    purchaseLimit: { required, numeric },
    transferLimit: { required, numeric },
  },
  methods: {
    changeComponent(e) {
      e.preventDefault();
      // this.$emit("switchRightComponent");
      this.bothLimits = true;
      this.cardTransferLimit = true;
      this.showAmount = false;
      this.cardPurchaseLimit = true;
      this.showAmountTwo = false;
    },
    getLimits() {
      let payload = {
        pan: this.cardDetail.pan,
        seqno: this.cardDetail.seqno,
        cardprog: this.cardDetail.cardtype.toLowerCase(),
      };

      this.loadingTwo = true;

      api
        .getCardLimits(payload)
        .then((response) => {
          this.loadingTwo = false;
          let responseStatus = response.Status;
          let responseMessage = response.Message;

          if (responseStatus) {
            let limits = response.Data;
            this.purchaseLimit = `#${limits.purchaselimit}`;
            this.transferLimit = `#${limits.paymentlimit}`;
          } else {
            this.$message({
              showClose: true,
              message: `${
                responseMessage.charAt(0).toUpperCase() + responseMessage.slice(1)
              }`,
              type: "error",
              duration: 10000,
            });
          }
        })
        .catch((error) => {
          this.loadingTwo = false;
          this.$message({
            showClose: true,
            message: `${error}`,
            type: "error",
            duration: 10000,
          });
        });
    },
    setPurchaseLimit() {
      this.showAmount = true;
      this.cardTransferLimit = false;
    },
    setTransferLimit() {
      this.showAmountTwo = true;
      this.cardPurchaseLimit = false;
    },
    submitForm() {
      // this.submitted = true;

      // this.$v.purchaselimit.$touch();

      // if (this.$v.purchaselimit.$invalid) {
      //   return;
      // }

      let payload = {
        pan: this.cardDetail.pan,
        seqno: this.cardDetail.seqno,
        cardprog: this.cardDetail.cardtype.toLowerCase(),
        smId: this.user.profileInfo.SMID,
        limit: `${this.purchaseLimit * 100}`,
      };

      this.loading = true;

      api
        .handleCardPurchaseLimit(payload)
        .then((response) => {
          this.loading = false;

          let responseStatus = response.Status;
          let responseMessage = response.Message;

          if (responseStatus) {
            this.$message({
              showClose: true,
              message: `${
                responseMessage.charAt(0).toUpperCase() + responseMessage.slice(1)
              }`,
              type: "success",
              duration: 10000,
            });
            this.$emit("switchRightComponent");
          } else {
            this.$message({
              showClose: true,
              message: `${
                responseMessage.charAt(0).toUpperCase() + responseMessage.slice(1)
              }`,
              type: "error",
              duration: 10000,
            });
          }
        })
        .catch((error) => {
          this.loading = false;
          this.$message({
            showClose: true,
            message: `${error}`,
            type: "error",
            duration: 10000,
          });
        });
    },
    submitFormTwo() {
      // this.submitted = true;

      // this.$v.purchaselimit.$touch();

      // if (this.$v.purchaselimit.$invalid) {
      //   return;
      // }

      let payload = {
        pan: this.cardDetail.pan,
        seqno: this.cardDetail.seqno,
        cardprog: this.cardDetail.cardtype.toLowerCase(),
        smId: this.user.profileInfo.SMID,
        limit: `${this.transferLimit * 100}`,
      };

      this.loading = true;

      api
        .handleCardPaymentLimit(payload)
        .then((response) => {
          this.loading = false;

          let responseStatus = response.Status;
          let responseMessage = response.Message;

          if (responseStatus) {
            this.$message({
              showClose: true,
              message: `${
                responseMessage.charAt(0).toUpperCase() + responseMessage.slice(1)
              }`,
              type: "success",
              duration: 10000,
            });
            this.$emit("switchRightComponent");
          } else {
            this.$message({
              showClose: true,
              message: `${
                responseMessage.charAt(0).toUpperCase() + responseMessage.slice(1)
              }`,
              type: "error",
              duration: 10000,
            });
          }
        })
        .catch((error) => {
          this.loading = false;
          this.$message({
            showClose: true,
            message: `${error}`,
            type: "error",
            duration: 10000,
          });
        });
    },
  },
  mounted() {
    this.getLimits();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_form.scss";
</style>
