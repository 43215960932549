<template>
  <div
    class="landing"
    v-loading="loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <svg
      width="303"
      height="220"
      viewBox="0 0 303 220"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d)">
        <path
          d="M185.106 49.842C185.106 49.842 176.88 58.745 161.706 49.842C146.532 40.9389 112.391 43.5518 106.062 71.3593C99.7332 99.1667 78.9659 91.7637 74.5289 105.859C70.0919 119.953 48.9278 121.071 47.8149 138.505C46.7021 155.938 64.1404 186.731 129.06 191.555C193.98 196.379 205.113 193.408 230.337 193.408C255.561 193.408 272.999 169.297 270.773 153.732C268.547 138.166 258.904 132.926 242.225 124.4C225.547 115.874 233.322 106.613 248.162 90.6653C263.002 74.7173 271.915 54.6661 261.154 40.9389C250.393 27.2118 229.621 26.0989 185.106 49.842Z"
          fill="#FFF4F5"
        />
        <path
          d="M79.7422 119.213H142.809L136.514 192.126C136.307 194.502 135.216 196.715 133.458 198.328C131.7 199.94 129.401 200.835 127.015 200.835H96.9241C94.5688 200.835 92.2969 199.963 90.5466 198.387C88.7963 196.811 87.6915 194.642 87.4452 192.3L79.7422 119.213Z"
          fill="#4F4F4F"
        />
        <path
          d="M95.3046 96.2151C95.3046 96.2151 93.4514 77.6639 82.6904 71.727C71.9293 65.79 64.144 76.9236 59.6925 77.2913C55.241 77.6591 51.8007 76.551 47.7702 81.3751C43.7396 86.1992 40.0284 97.6957 32.6108 93.9894C25.1932 90.283 20.7369 95.47 15.9176 106.231C11.0984 116.992 10 148.153 10 148.153C10 148.153 12.9661 138.505 23.3546 136.279C33.743 134.053 35.2285 127.376 37.0817 119.213C38.9349 111.05 48.2105 112.536 52.662 115.507C57.1135 118.478 64.9037 116.247 66.3891 105.486C67.8746 94.7248 67.8407 90.8733 79.7437 91.7636C91.6466 92.6539 95.3046 96.2151 95.3046 96.2151Z"
          fill="#FFC2C4"
        />
        <path
          d="M136.132 16.8234C136.132 16.8234 153.198 -1.35523 181.392 9.40583C181.392 9.40583 172.117 12.7445 169.891 26.8248C167.665 40.9052 165.052 49.455 147.261 50.1953C129.469 50.9356 138.73 62.0692 136.132 67.6143C133.534 73.1593 124.263 72.4529 124.263 79.8559C124.263 87.259 96.4359 89.1654 95.3037 96.2153C95.3037 96.2153 89.0135 75.4383 93.8231 65.0498C98.6326 54.6613 106.805 55.3387 108.658 51.1049C110.511 46.8712 109.771 31.2667 116.821 28.3248C123.871 25.3829 127.582 29.4377 130.548 26.8393C133.514 24.241 136.132 16.8234 136.132 16.8234Z"
          fill="#760004"
        />
        <path
          d="M95.3047 96.2151C97.3853 88.183 100.705 80.509 104.58 73.1785C108.495 65.8507 113.093 58.9088 118.312 52.4451C119.604 50.8193 120.978 49.2612 122.338 47.6935C123.03 46.9194 123.746 46.1645 124.448 45.4L125.502 44.2581L126.596 43.1501C129.499 40.1598 132.557 37.3728 135.712 34.6728L138.131 32.7035C138.538 32.3794 138.93 32.0455 139.341 31.7358L140.584 30.7971L143.067 28.9197L145.626 27.1295C147.31 25.9198 149.086 24.8456 150.828 23.7424C151.689 23.1666 152.603 22.6683 153.489 22.136L156.17 20.5635L153.523 22.1892C152.642 22.736 151.742 23.2537 150.891 23.8441C149.173 25.0005 147.421 26.1085 145.762 27.352L143.241 29.1617L140.788 31.0294L139.563 31.9971C139.157 32.3164 138.765 32.6552 138.363 32.9648L135.978 34.9487C123.399 45.7702 112.883 58.7797 104.938 73.3478C102.955 76.98 101.147 80.7025 99.5143 84.5153C97.8478 88.3154 96.4413 92.2243 95.3047 96.2151Z"
          fill="#DB353A"
        />
        <path
          d="M23.7266 119.213C27.9555 112.908 32.699 106.964 37.9085 101.441C40.5095 98.6692 43.2707 96.0519 46.1777 93.6027C49.0696 91.1272 52.1771 88.9152 55.463 86.9931C55.8694 86.7464 56.2904 86.5093 56.7162 86.3012L57.9839 85.6383L59.2806 85.0432L59.9338 84.748L60.5967 84.4867C62.3662 83.7646 64.198 83.2058 66.0692 82.8174C69.818 82.0091 73.6991 82.0388 77.4351 82.9045C81.1506 83.8239 84.6309 85.5154 87.6494 87.8689C89.1555 89.0302 90.5606 90.317 91.8493 91.7156C93.1443 93.1047 94.3073 94.6112 95.3234 96.2155C94.2781 94.6341 93.0858 93.155 91.7622 91.7979C90.4547 90.4311 89.0317 89.1797 87.5091 88.0576C84.4954 85.7661 81.0334 84.1341 77.348 83.2674C73.6598 82.457 69.8396 82.457 66.1515 83.2674C64.3095 83.6574 62.5069 84.2145 60.7661 84.9319L60.108 85.1932L59.4693 85.4883L58.1871 86.0835C57.3597 86.5335 56.5033 86.9351 55.7001 87.4286C52.4293 89.326 49.3348 91.512 46.4535 93.9607C43.557 96.3909 40.7933 98.9752 38.1746 101.703C35.5376 104.417 33.0312 107.262 30.6264 110.189C28.2216 113.117 25.8894 116.102 23.7266 119.213Z"
          fill="#DB353A"
        />
        <path
          d="M51.3454 104.746C53.3096 104.746 54.9018 103.153 54.9018 101.189C54.9018 99.2251 53.3096 97.6328 51.3454 97.6328C49.3813 97.6328 47.7891 99.2251 47.7891 101.189C47.7891 103.153 49.3813 104.746 51.3454 104.746Z"
          fill="#DB353A"
        />
        <path
          d="M26.771 102.341C26.7719 102.642 26.6834 102.937 26.5165 103.189C26.3496 103.44 26.1119 103.636 25.8334 103.752C25.555 103.868 25.2484 103.899 24.9524 103.841C24.6565 103.783 24.3844 103.638 24.1708 103.425C23.9571 103.212 23.8115 102.94 23.7523 102.645C23.6931 102.349 23.723 102.042 23.8382 101.763C23.9534 101.485 24.1488 101.246 24.3995 101.078C24.6503 100.911 24.9452 100.821 25.2468 100.821C25.6502 100.821 26.0372 100.981 26.3228 101.266C26.6085 101.551 26.7697 101.937 26.771 102.341Z"
          fill="#DB353A"
        />
        <path
          d="M22.0093 127.651C23.57 127.651 24.8351 126.386 24.8351 124.826C24.8351 123.265 23.57 122 22.0093 122C20.4487 122 19.1836 123.265 19.1836 124.826C19.1836 126.386 20.4487 127.651 22.0093 127.651Z"
          fill="#DB353A"
        />
        <path
          d="M78.6888 80.5816C80.383 80.5816 81.7564 79.2082 81.7564 77.514C81.7564 75.8197 80.383 74.4463 78.6888 74.4463C76.9945 74.4463 75.6211 75.8197 75.6211 77.514C75.6211 79.2082 76.9945 80.5816 78.6888 80.5816Z"
          fill="#DB353A"
        />
        <path
          d="M121.892 71.655C124.853 71.655 127.254 69.2547 127.254 66.2938C127.254 63.3329 124.853 60.9326 121.892 60.9326C118.932 60.9326 116.531 63.3329 116.531 66.2938C116.531 69.2547 118.932 71.655 121.892 71.655Z"
          fill="#DB353A"
        />
        <path
          d="M118.481 39.1821C119.558 39.1821 120.431 38.3091 120.431 37.2322C120.431 36.1553 119.558 35.2822 118.481 35.2822C117.404 35.2822 116.531 36.1553 116.531 37.2322C116.531 38.3091 117.404 39.1821 118.481 39.1821Z"
          fill="#DB353A"
        />
        <path
          d="M100.962 66.5355C101.424 66.5355 101.799 66.1607 101.799 65.6984C101.799 65.2361 101.424 64.8613 100.962 64.8613C100.5 64.8613 100.125 65.2361 100.125 65.6984C100.125 66.1607 100.5 66.5355 100.962 66.5355Z"
          fill="#DB353A"
        />
        <path
          d="M154.181 42.3037C157.251 42.3037 159.74 39.8146 159.74 36.7441C159.74 33.6737 157.251 31.1846 154.181 31.1846C151.11 31.1846 148.621 33.6737 148.621 36.7441C148.621 39.8146 151.11 42.3037 154.181 42.3037Z"
          fill="#DB353A"
        />
        <path
          d="M145.465 20.5345C146.932 20.5345 148.121 19.3452 148.121 17.8781C148.121 16.411 146.932 15.2217 145.465 15.2217C143.998 15.2217 142.809 16.411 142.809 17.8781C142.809 19.3452 143.998 20.5345 145.465 20.5345Z"
          fill="#DB353A"
        />
        <path
          d="M187.278 69.874H131.446C127.448 69.874 124.207 73.1148 124.207 77.1126V197.299C124.207 201.296 127.448 204.537 131.446 204.537H187.278C191.276 204.537 194.517 201.296 194.517 197.299V77.1126C194.517 73.1148 191.276 69.874 187.278 69.874Z"
          fill="#BE1217"
        />
        <path
          d="M187.024 74.4463H131.704C129.652 74.4463 127.988 76.11 127.988 78.1623V186.523C127.988 188.575 129.652 190.239 131.704 190.239H187.024C189.076 190.239 190.74 188.575 190.74 186.523V78.1623C190.74 76.11 189.076 74.4463 187.024 74.4463Z"
          fill="#F2F2F2"
        />
        <path
          d="M125.504 72.9854C124.66 74.1962 124.207 75.6367 124.207 77.1127V197.299C124.207 199.224 124.972 201.07 126.333 202.431C127.694 203.792 129.54 204.557 131.465 204.557H187.278C188.405 204.559 189.518 204.298 190.526 203.794C191.534 203.29 192.41 202.557 193.085 201.654L125.504 72.9854Z"
          fill="#850004"
        />
        <path
          d="M128.535 76.2266C128.176 76.8082 127.987 77.4785 127.988 78.162V186.547C127.988 187.034 128.083 187.517 128.27 187.968C128.456 188.418 128.729 188.828 129.074 189.172C129.419 189.517 129.828 189.791 130.279 189.977C130.729 190.163 131.212 190.259 131.7 190.258H187.019C187.611 190.258 188.194 190.117 188.72 189.846C189.246 189.575 189.7 189.182 190.043 188.7L128.535 76.2266Z"
          fill="#FBFBFB"
        />
        <path
          d="M159.392 203.206C162.574 203.206 165.154 200.626 165.154 197.443C165.154 194.261 162.574 191.681 159.392 191.681C156.209 191.681 153.629 194.261 153.629 197.443C153.629 200.626 156.209 203.206 159.392 203.206Z"
          fill="#AC1419"
        />
        <path
          d="M178.203 137.663C180.594 127.711 174.465 117.706 164.514 115.315C154.562 112.924 144.556 119.053 142.165 129.004C139.774 138.956 145.903 148.962 155.855 151.353C165.807 153.744 175.812 147.615 178.203 137.663Z"
          stroke="#C4C4C4"
          stroke-width="2"
          stroke-miterlimit="10"
        />
        <path
          d="M152.207 131.643L157.65 139.801L171.997 126.384"
          stroke="#C4C4C4"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M236.306 131.644H174.464C173.101 131.644 171.996 132.748 171.996 134.111V166.752C171.996 168.115 173.101 169.22 174.464 169.22H236.306C237.669 169.22 238.774 168.115 238.774 166.752V134.111C238.774 132.748 237.669 131.644 236.306 131.644Z"
          fill="black"
        />
        <path
          d="M172.721 132.346C172.487 132.576 172.301 132.85 172.174 133.152C172.047 133.454 171.981 133.779 171.98 134.107V166.753C171.98 167.406 172.24 168.033 172.702 168.495C173.164 168.956 173.79 169.216 174.443 169.216H236.3C236.648 169.216 236.992 169.142 237.309 168.998C237.626 168.855 237.909 168.645 238.139 168.384L172.721 132.346Z"
          fill="#4F4F4F"
        />
        <path
          d="M238.774 157.424H171.996V162.248H238.774V157.424Z"
          fill="#BE1217"
        />
        <path
          d="M220.544 146.483H190.738V151.864H220.544V146.483Z"
          fill="#FFF4F5"
        />
        <path
          d="M39.2851 148.153C39.2842 148.809 39.089 149.449 38.7242 149.993C38.3594 150.538 37.8415 150.962 37.2357 151.212C36.63 151.462 35.9637 151.527 35.3211 151.399C34.6785 151.27 34.0884 150.954 33.6253 150.49C33.1623 150.027 32.8471 149.436 32.7196 148.793C32.5921 148.15 32.658 147.484 32.909 146.879C33.16 146.274 33.5848 145.756 34.1298 145.392C34.6747 145.028 35.3154 144.834 35.9707 144.834C36.4064 144.834 36.8378 144.92 37.2402 145.087C37.6426 145.254 38.0082 145.498 38.3161 145.806C38.6239 146.115 38.868 146.481 39.0342 146.883C39.2005 147.286 39.2858 147.718 39.2851 148.153V148.153Z"
          stroke="#FFC2C4"
          stroke-width="2"
          stroke-miterlimit="10"
        />
        <path
          d="M59.4219 186.925C59.4219 187.581 59.2274 188.222 58.8629 188.767C58.4985 189.312 57.9806 189.737 57.3747 189.987C56.7688 190.238 56.1021 190.303 55.459 190.175C54.8159 190.047 54.2254 189.731 53.762 189.267C53.2987 188.803 52.9834 188.212 52.8561 187.568C52.7287 186.925 52.7951 186.259 53.0467 185.653C53.2983 185.048 53.7238 184.53 54.2695 184.167C54.8152 183.803 55.4565 183.609 56.1123 183.61C56.5473 183.61 56.9781 183.696 57.3799 183.863C57.7818 184.029 58.1468 184.274 58.4542 184.581C58.7616 184.889 59.0053 185.255 59.1713 185.657C59.3374 186.059 59.4225 186.49 59.4219 186.925V186.925Z"
          stroke="#FFC2C4"
          stroke-width="2"
          stroke-miterlimit="10"
        />
        <path
          d="M291.895 157.424C291.895 158.079 291.7 158.72 291.336 159.265C290.972 159.81 290.454 160.235 289.848 160.486C289.243 160.737 288.576 160.802 287.933 160.675C287.291 160.547 286.7 160.231 286.236 159.767C285.773 159.304 285.457 158.713 285.329 158.07C285.201 157.427 285.267 156.761 285.518 156.155C285.769 155.55 286.194 155.032 286.739 154.668C287.284 154.304 287.925 154.109 288.58 154.109C289.015 154.109 289.446 154.195 289.848 154.362C290.251 154.528 290.616 154.772 290.924 155.08C291.232 155.388 291.476 155.753 291.642 156.155C291.809 156.558 291.895 156.989 291.895 157.424V157.424Z"
          stroke="#FFC2C4"
          stroke-width="2"
          stroke-miterlimit="10"
        />
        <path
          d="M188.02 20.5342C188.02 21.1897 187.825 21.8305 187.461 22.3756C187.097 22.9206 186.579 23.3455 185.973 23.5963C185.368 23.8472 184.701 23.9128 184.058 23.7849C183.416 23.657 182.825 23.3414 182.361 22.8778C181.898 22.4143 181.582 21.8237 181.454 21.1808C181.326 20.5378 181.392 19.8714 181.643 19.2658C181.894 18.6602 182.319 18.1425 182.864 17.7783C183.409 17.4141 184.05 17.2197 184.705 17.2197C185.14 17.2197 185.571 17.3055 185.973 17.472C186.376 17.6386 186.741 17.8827 187.049 18.1905C187.357 18.4983 187.601 18.8637 187.767 19.2658C187.934 19.6679 188.02 20.0989 188.02 20.5342V20.5342Z"
          stroke="#FFC2C4"
          stroke-width="2"
          stroke-miterlimit="10"
        />
        <path
          d="M100.145 17.2206C100.144 17.876 99.9483 18.5163 99.5836 19.0607C99.2188 19.6052 98.7008 20.0292 98.0951 20.2793C97.4894 20.5295 96.8231 20.5944 96.1805 20.466C95.5379 20.3375 94.9477 20.0215 94.4847 19.5578C94.0216 19.094 93.7065 18.5035 93.579 17.8607C93.4515 17.2178 93.5174 16.5517 93.7684 15.9463C94.0194 15.341 94.4442 14.8236 94.9892 14.4596C95.5341 14.0956 96.1747 13.9014 96.8301 13.9014C97.2657 13.9014 97.6971 13.9873 98.0996 14.1541C98.502 14.321 98.8676 14.5656 99.1754 14.8739C99.4833 15.1821 99.7273 15.5481 99.8936 15.9508C100.06 16.3535 100.145 16.785 100.145 17.2206Z"
          stroke="#FFC2C4"
          stroke-width="2"
          stroke-miterlimit="10"
        />
        <path
          d="M49.1172 46.2324C49.1172 46.888 48.9228 47.5288 48.5586 48.0738C48.1944 48.6189 47.6768 49.0437 47.0711 49.2946C46.4655 49.5454 45.7991 49.6111 45.1561 49.4832C44.5132 49.3553 43.9226 49.0396 43.4591 48.5761C42.9955 48.1126 42.6799 47.522 42.552 46.879C42.4241 46.2361 42.4897 45.5697 42.7406 44.964C42.9914 44.3584 43.4163 43.8407 43.9613 43.4766C44.5064 43.1124 45.1472 42.918 45.8027 42.918C46.6818 42.918 47.5248 43.2672 48.1464 43.8888C48.768 44.5103 49.1172 45.3534 49.1172 46.2324V46.2324Z"
          stroke="#FFC2C4"
          stroke-width="2"
          stroke-miterlimit="10"
        />
        <path
          d="M270.871 96.2148C270.87 96.8701 270.675 97.5105 270.31 98.0549C269.945 98.5993 269.427 99.0234 268.822 99.2735C268.216 99.5236 267.55 99.5886 266.907 99.4601C266.264 99.3317 265.674 99.0156 265.211 98.5519C264.748 98.0882 264.433 97.4976 264.306 96.8548C264.178 96.212 264.244 95.5458 264.495 94.9405C264.746 94.3351 265.171 93.8177 265.716 93.4538C266.261 93.0898 266.901 92.8955 267.557 92.8955C267.992 92.8955 268.424 92.9814 268.826 93.1483C269.229 93.3151 269.594 93.5597 269.902 93.868C270.21 94.1763 270.454 94.5422 270.62 94.9449C270.786 95.3476 270.872 95.7791 270.871 96.2148V96.2148Z"
          stroke="#FFC2C4"
          stroke-width="2"
          stroke-miterlimit="10"
        />
        <path
          d="M210.421 128.305L209.695 126.079H214.408L210.421 128.305Z"
          fill="#E7A78D"
        />
        <path
          d="M203.277 132.805L203.65 141.379C203.65 141.379 214.503 142.216 222.201 139.801L220.14 128.305L204.956 129.224L203.277 132.805Z"
          fill="white"
        />
        <path
          d="M197.156 98.0682C197.156 98.0682 198.085 104.281 198.55 104.842C198.55 104.842 201.608 108.273 204.579 107.997C207.55 107.721 208.566 103.545 208.658 102.525C208.75 101.504 207.729 95.8473 207.729 95.8473C207.729 95.8473 203.742 96.6795 202.261 94.7344C202.261 94.7295 200.592 98.3488 197.156 98.0682Z"
          fill="#E7A78D"
        />
        <path
          d="M202.263 94.7299C203.744 96.6653 207.731 95.8427 207.731 95.8427C207.731 95.8427 210.803 92.9396 208.665 90.0364C206.526 87.1332 202.82 88.1445 200.304 90.7428C200.304 90.7428 198.542 89.1655 195.388 90.7428C192.233 92.3202 191.957 97.7879 197.149 98.0685C200.594 98.3491 202.263 94.7299 202.263 94.7299Z"
          fill="#4F4F4F"
        />
        <path
          d="M195.234 97.6328C195.234 97.6328 195.83 101.344 197.886 102.346C197.509 100.41 197.155 98.0586 197.155 98.0586C196.494 98.0349 195.843 97.8906 195.234 97.6328Z"
          fill="#4F4F4F"
        />
        <path
          d="M209.011 93.9844C208.694 94.6754 208.256 95.3045 207.719 95.8424C207.719 95.8424 208.14 98.1553 208.416 100.091L208.653 99.9988C208.653 99.9988 209.369 97.9278 209.011 93.9844Z"
          fill="#4F4F4F"
        />
        <path
          d="M197.524 100.353C197.275 100.396 197.047 100.518 196.873 100.701C196.699 100.884 196.588 101.118 196.556 101.369C196.411 102.273 196.789 103.788 198.206 103.865L197.524 100.353Z"
          fill="#E7A78D"
        />
        <path
          d="M208.176 98.456C208.249 98.3606 208.344 98.2833 208.452 98.2302C208.56 98.177 208.679 98.1494 208.8 98.1494C208.921 98.1494 209.039 98.177 209.148 98.2302C209.256 98.2833 209.35 98.3606 209.424 98.456C210.019 99.1818 209.584 100.875 208.626 101.708L208.176 98.456Z"
          fill="#E7A78D"
        />
        <path
          d="M202.912 99.6123C202.912 99.6123 202.733 102.274 202.912 102.583C203.024 102.714 203.172 102.808 203.337 102.856C203.503 102.903 203.678 102.901 203.843 102.849C204.007 102.798 204.152 102.699 204.261 102.566C204.37 102.433 204.437 102.271 204.455 102.099C204.658 100.977 202.912 99.6123 202.912 99.6123Z"
          fill="#DD9078"
        />
        <path
          d="M201.27 101.025C201.323 101.582 201.047 102.061 200.646 102.104C200.244 102.148 199.876 101.727 199.823 101.17C199.77 100.614 200.046 100.135 200.442 100.096C200.839 100.058 201.212 100.474 201.27 101.025Z"
          fill="#AC1419"
        />
        <path
          d="M206.594 100.018C206.652 100.57 206.371 101.054 205.97 101.093C205.568 101.131 205.2 100.715 205.147 100.164C205.094 99.6119 205.37 99.1232 205.771 99.0845C206.173 99.0458 206.56 99.4619 206.594 100.018Z"
          fill="#AC1419"
        />
        <path
          d="M201.752 103.865C201.946 104.09 202.177 104.28 202.435 104.427C202.553 104.484 202.678 104.527 202.807 104.552C202.948 104.591 203.107 104.606 203.291 104.654C203.166 104.798 202.995 104.895 202.807 104.93C202.606 104.973 202.397 104.948 202.212 104.857C202.034 104.769 201.891 104.623 201.807 104.442C201.724 104.262 201.704 104.058 201.752 103.865Z"
          fill="#D1927C"
        />
        <path
          d="M207.425 109.014C207.115 108.641 206.854 106.769 206.854 106.769C206.281 107.454 205.467 107.892 204.579 107.993C203.586 108.04 202.6 107.806 201.734 107.315C201.734 107.315 202.475 109.691 202.509 110.064C202.459 110.413 202.326 110.744 202.121 111.031C202.121 111.031 205.508 113.63 206.433 113.32C207.357 113.01 208.175 109.304 208.175 109.304C208.036 109.318 207.895 109.299 207.765 109.248C207.635 109.198 207.518 109.118 207.425 109.014Z"
          fill="#E7A78D"
        />
        <path
          d="M221.108 133.758L220.14 128.305L204.956 129.224L203.277 132.8L203.423 136.085C207.139 136.284 214.972 136.308 221.108 133.758Z"
          fill="#E5E5E5"
        />
        <path
          d="M200.543 113.092C200.543 113.092 196.697 117.021 196.043 118.536C195.39 120.05 194.074 130.676 195.235 132.345C196.397 134.015 198.922 136.836 210.419 128.305L211.532 131.644C211.532 131.644 222.849 131.087 225.259 127.652C227.669 124.216 221.828 115.134 220.715 113.092C219.603 111.051 210.883 110.068 210.883 110.068C210.883 110.068 208.009 112.846 206.432 113.339C204.855 113.833 200.543 113.092 200.543 113.092Z"
          fill="white"
        />
        <path
          d="M202.12 111.05L200.543 113.092L204.956 118.535L206.432 113.338C205.503 113.648 202.12 111.05 202.12 111.05Z"
          fill="#E5E5E5"
        />
        <path
          d="M208.175 109.323C208.175 109.323 207.363 113.03 206.434 113.339L210.885 116.62V110.068L208.175 109.323Z"
          fill="#E5E5E5"
        />
        <path
          d="M211.533 131.643L205.887 131.382L210.42 128.305L211.533 131.643Z"
          fill="#E7A78D"
        />
        <path
          d="M205.5 116.615L207.082 118.536L208.935 118.008L209.124 115.318L206.434 113.339L205.5 116.615Z"
          fill="#F2F2F2"
        />
        <path
          d="M207.73 125.997L207.082 118.536L208.935 118.009L213.3 125.997H207.73Z"
          fill="#F2F2F2"
        />
        <path
          d="M222.2 139.802C214.501 142.221 203.648 141.379 203.648 141.379C203.648 141.379 204.515 163.095 205.139 169.791C205.139 169.791 202.85 172.637 207.074 198.339C207.074 198.339 209.827 198.954 212.053 198.339C212.053 198.339 212.537 176.449 212.73 170.885C212.924 165.321 214.405 149.368 214.405 149.368L220.138 169.172C220.138 169.172 217.976 172.017 216.616 178.448C215.256 184.878 213.272 192.794 213.272 192.794C213.272 192.794 216.118 195.513 217.85 195.141C217.85 195.141 227.745 172.264 228.611 168.679C229.477 165.093 222.2 139.802 222.2 139.802Z"
          fill="#500002"
        />
        <path
          d="M207.075 198.358C207.075 198.358 202.159 203.889 202.532 205.191C202.904 206.492 211.435 206.023 212.083 205.191C212.732 204.358 212.054 198.358 212.054 198.358C210.399 198.538 208.73 198.538 207.075 198.358Z"
          fill="#BE1217"
        />
        <path
          d="M213.294 192.794C213.294 192.794 212.133 195.266 212.118 195.823C212.104 196.379 212.23 200.178 212.23 200.178C212.23 200.178 212.936 201.194 216.178 201.102C219.42 201.01 220.16 201.291 220.436 200.734C220.712 200.178 220.533 198.6 218.767 197.952C217 197.304 217.871 195.136 217.871 195.136C217.871 195.136 216.173 195.325 213.294 192.794Z"
          fill="#BE1217"
        />
        <path
          d="M207.076 107.934C206.951 107.363 206.873 106.792 206.873 106.792C206.301 107.477 205.486 107.916 204.599 108.016C203.606 108.064 202.619 107.829 201.754 107.339C201.754 107.339 201.972 108.04 202.175 108.737C203.317 108.979 205.393 109.177 207.076 107.934Z"
          fill="#D1927C"
        />
        <path
          d="M198.734 127.652C199.326 126.867 200.151 126.29 201.091 126.002C201.566 125.88 202.052 125.812 202.542 125.798C203.026 125.774 203.51 125.765 203.994 125.755C205.904 125.737 207.813 125.845 209.708 126.079C207.802 126.215 205.9 126.224 203.999 126.239C203.515 126.239 203.031 126.239 202.581 126.239C202.119 126.235 201.657 126.274 201.202 126.355C200.283 126.57 199.433 127.017 198.734 127.652Z"
          fill="#E5E5E5"
        />
        <path
          d="M198.734 122.116C199.629 123.397 200.245 124.851 200.544 126.384C199.652 125.102 199.035 123.649 198.734 122.116Z"
          fill="#E5E5E5"
        />
        <path
          d="M214.414 126.079C214.795 125.729 215.219 125.43 215.677 125.188C216.133 124.945 216.609 124.741 217.099 124.579C217.592 124.416 218.099 124.302 218.614 124.24C219.128 124.17 219.649 124.17 220.162 124.24C219.678 124.371 219.195 124.468 218.711 124.603C218.227 124.738 217.743 124.869 217.259 125.029C216.301 125.353 215.372 125.706 214.414 126.079Z"
          fill="#E5E5E5"
        />
        <path
          d="M217.843 117.544C218.118 118.637 218.307 119.75 218.409 120.873C218.527 121.998 218.554 123.131 218.492 124.26C217.954 122.067 217.735 119.809 217.843 117.554V117.544Z"
          fill="#E5E5E5"
        />
        <path
          d="M209.926 147.712C213.175 147.812 216.42 147.397 219.54 146.483C218.127 147.9 216.353 148.904 214.411 149.387C214.411 149.387 211.431 149.367 209.926 147.712Z"
          fill="#7D0004"
        />
        <path
          d="M155.72 91.1299H97.6669C95.6547 91.1299 94.0234 92.7611 94.0234 94.7734V126.326C94.0234 128.338 95.6547 129.969 97.6669 129.969H155.72C157.733 129.969 159.364 128.338 159.364 126.326V94.7734C159.364 92.7611 157.733 91.1299 155.72 91.1299Z"
          fill="#4F4F4F"
        />
        <path
          d="M94.0863 94.1055C94.0455 94.3274 94.0245 94.5524 94.0234 94.778V126.321C94.0234 127.288 94.4071 128.215 95.0903 128.899C95.7734 129.583 96.7002 129.968 97.6669 129.969H155.73C156.259 129.969 156.781 129.854 157.261 129.632C157.74 129.41 158.166 129.086 158.508 128.682L94.0863 94.1055Z"
          fill="black"
        />
        <path
          d="M102.388 112.749H98.5408C97.5841 112.749 96.8086 113.525 96.8086 114.481V118.328C96.8086 119.285 97.5841 120.06 98.5408 120.06H102.388C103.344 120.06 104.12 119.285 104.12 118.328V114.481C104.12 113.525 103.344 112.749 102.388 112.749Z"
          fill="#FFF4F5"
        />
        <path
          d="M154.278 114.897H107.305V117.544H154.278V114.897Z"
          fill="#BE1217"
        />
        <path
          d="M279.086 64.8662L283.112 68.2048"
          stroke="#DB353A"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M283.112 64.8662L279.086 68.2048"
          stroke="#DB353A"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M77.7305 46.2324L81.7562 49.5711"
          stroke="#DB353A"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M81.7562 46.2324L77.7305 49.5711"
          stroke="#DB353A"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M268.977 190.012L273.002 193.35"
          stroke="#DB353A"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M273.002 190.012L268.977 193.35"
          stroke="#DB353A"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M25.4062 173.004L29.432 176.347"
          stroke="#DB353A"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M29.432 173.004L25.4062 176.347"
          stroke="#DB353A"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M84.005 119.213H79.7422L87.4452 192.276C87.6859 194.623 88.7883 196.797 90.5392 198.378C92.2901 199.959 94.565 200.834 96.9241 200.835H125.506L84.005 119.213Z"
          fill="#333333"
        />
      </g>
      <defs>
        <filter
          id="filter0_d"
          x="0"
          y="0"
          width="302.895"
          height="220"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.995833 0 0 0 0 0.871354 0 0 0 0 0.871354 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
    <p>Select a task on the Menu List</p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false
    };
  }
};
</script>

<style lang="scss" scoped>
.landing {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  svg {
    margin-bottom: 2rem;
    width: 100%;
  }
  p {
    font-family: Red Hat Display;
    font-style: normal;
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 2.4rem;
    text-align: center;
    letter-spacing: 0.05em;
    color: #000000;
  }
}
</style>
