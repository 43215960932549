<template>
  <div v-loading="loadingTwo" element-loading-background="rgba(0, 0, 0, 0.8)">
    <div>
      <h2 class="leading--two">Block Card</h2>
      <p class="leading--two">Would you like to block card?</p>
    </div>

    <div class="btn__grid-two">
      <button class="btn btn__black" @click="goBack">
        No
      </button>
      <button class="btn btn__red btn__group" @click="handleBlockCard">
        <loading v-if="loading" />
        <span>Yes</span>
      </button>
    </div>
  </div>
</template>

<script>
import api from "@/api/api.js";
import { mapState } from "vuex";
export default {
  computed: mapState(["userDetailsTwo", "user", "cardDetail"]),
  data() {
    return {
      loading: false,
      loadingTwo: false
    };
  },
  methods: {
    goBack() {
      this.$emit("switchRightComponent");
    },
    handleBlockCard() {
      let payload = {
        PAN: this.cardDetail.pan,
        ExpiryDate: this.cardDetail.exp,
        CustomerId: this.userDetailsTwo.customerid
      };

      this.loading = true;

      api
        .blockCard(payload)
        .then(response => {
          this.loading = false;

          let responseStatus = response.Status;
          let responseMessage = response.Message;

          if (responseStatus) {
            this.$message({
              showClose: true,
              message: `${responseMessage.charAt(0).toUpperCase() +
                responseMessage.slice(1)}`,
              type: "success",
              duration: 10000
            });
            this.$emit("switchRightComponent");
          } else {
            this.$message({
              showClose: true,
              message: `${responseMessage.charAt(0).toUpperCase() +
                responseMessage.slice(1)}`,
              type: "error",
              duration: 10000
            });
          }
        })
        .catch(error => {
          this.loading = false;
          this.$message({
            showClose: true,
            message: `${error}`,
            type: "error",
            duration: 10000
          });
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_form.scss";
</style>
