<template>
  <div class="actions">
    <div class="actions__left">
      <div class="main-card">
        <div class="card">
          <div class="card__top">
            <svg
              v-if="cardDetail.cardtype === 'SBP_MASTERCARD'"
              width="33"
              height="20"
              viewBox="0 0 33 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19.994 9.99994C19.994 15.524 15.519 19.9999 9.99698 19.9999C4.47495 19.9999 0 15.523 0 9.99994C0 4.47692 4.47604 0 9.99698 0C15.5179 0 19.994 4.47692 19.994 9.99994Z"
                fill="#E2574C"
              />
              <path
                d="M22.003 0C19.761 0 17.697 0.747013 16.029 1.99404L16.037 1.99503C16.365 2.312 16.727 2.53508 17.006 2.89998L14.926 2.93299C14.6 3.26202 14.303 3.62001 14.023 3.99204H17.6909C17.9699 4.32699 18.2279 4.61808 18.462 4.98802H13.358C13.171 5.31003 12.998 5.64104 12.847 5.98499H19.043C19.205 6.32795 19.35 6.587 19.4731 6.95004H12.48C12.369 7.29508 12.2741 7.64802 12.202 8.00799H19.766C19.84 8.35402 19.897 8.67395 19.936 9.00002H12.052C12.019 9.32905 12.002 9.66302 12.002 10.0001H19.9929C19.9929 10.3541 19.9679 10.682 19.932 11.0001H12.052C12.086 11.3391 12.136 11.6721 12.202 12.0001H19.754C19.676 12.3241 19.5859 12.6501 19.473 12.9881H12.457C12.563 13.3301 12.692 13.6621 12.833 13.9861H19.043C18.871 14.3501 18.676 14.6411 18.4611 14.982H13.3401C13.5422 15.332 13.7651 15.666 14.0071 15.986L17.691 16.041C17.377 16.4179 16.9741 16.645 16.607 16.975C16.6269 16.991 16.02 16.973 14.8249 16.954C16.643 18.8301 19.184 20 22.0029 20C27.5259 20 32.0028 15.523 32.0028 10.0001C32.0028 4.47714 27.5269 0 22.003 0Z"
                fill="#F4B459"
              />
            </svg>
          </div>
          <div class="card__num">{{ cardDetail.pan | formatCard }}</div>
          <div class="card__bottom">
            <div class="card__name">
              <span>Card Holder</span
              >{{ userDetailsTwo.accountname | capitalize }}
            </div>
            <div class="card__exp">
              <span>Expires</span>{{ cardDetail.exp | capitalize }}
            </div>
          </div>
        </div>
      </div>
      <div class="menu">
        <h2>Menu</h2>
        <ul>
          <li @click="selected = 'app-set-pin'">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.875 19.0234H3.125C1.40188 19.0234 0 17.6216 0 15.8984C0 14.1753 1.40188 12.7734 3.125 12.7734H16.875C18.5981 12.7734 20 14.1753 20 15.8984C20 17.6216 18.5981 19.0234 16.875 19.0234Z"
                fill="#F7F2F4"
              />
              <path
                d="M8.04688 16.875C8.58622 16.875 9.02344 16.4378 9.02344 15.8984C9.02344 15.3591 8.58622 14.9219 8.04688 14.9219C7.50753 14.9219 7.07031 15.3591 7.07031 15.8984C7.07031 16.4378 7.50753 16.875 8.04688 16.875Z"
                fill="#6D737F"
              />
              <path
                d="M4.14062 16.875C4.67997 16.875 5.11719 16.4378 5.11719 15.8984C5.11719 15.3591 4.67997 14.9219 4.14062 14.9219C3.60128 14.9219 3.16406 15.3591 3.16406 15.8984C3.16406 16.4378 3.60128 16.875 4.14062 16.875Z"
                fill="#6D737F"
              />
              <path
                d="M12.3438 6.13281H7.65625C7.33266 6.13281 7.07031 5.87047 7.07031 5.54688V3.90625C7.07031 2.29082 8.38457 0.976562 10 0.976562C11.6154 0.976562 12.9297 2.29082 12.9297 3.90625V5.54688C12.9297 5.87047 12.6673 6.13281 12.3438 6.13281ZM8.24219 4.96094H11.7578V3.90625C11.7578 2.93699 10.9693 2.14844 10 2.14844C9.03074 2.14844 8.24219 2.93699 8.24219 3.90625V4.96094Z"
                fill="#6D737F"
              />
              <path
                d="M10 0.976562V2.14844C10.9693 2.14844 11.7578 2.93699 11.7578 3.90625V4.96094H10V6.13281H12.3438C12.6673 6.13281 12.9297 5.87047 12.9297 5.54688V3.90625C12.9297 2.29082 11.6154 0.976562 10 0.976562Z"
                fill="#5E5F69"
              />
              <path
                d="M13.7109 10.7812H6.28906C5.96547 10.7812 5.70312 10.5189 5.70312 10.1953V5.54688C5.70312 5.22328 5.96547 4.96094 6.28906 4.96094H13.7109C14.0345 4.96094 14.2969 5.22328 14.2969 5.54688V10.1953C14.2969 10.5189 14.0345 10.7812 13.7109 10.7812Z"
                fill="#E60000"
              />
              <path
                d="M13.7109 4.96094H10V10.7812H13.7109C14.0345 10.7812 14.2969 10.5189 14.2969 10.1953V5.54688C14.2969 5.22328 14.0345 4.96094 13.7109 4.96094Z"
                fill="#CB0000"
              />
              <path
                d="M10.9766 7.5C10.9766 7.17641 10.7142 6.91406 10.3906 6.91406H9.60938C9.28578 6.91406 9.02344 7.17641 9.02344 7.5C9.02344 7.755 9.18664 7.97137 9.41406 8.05188V8.28125C9.41406 8.60484 9.67641 8.86719 10 8.86719C10.3236 8.86719 10.5859 8.60484 10.5859 8.28125V8.05188C10.8134 7.97137 10.9766 7.755 10.9766 7.5Z"
                fill="#CB0000"
              />
              <path
                d="M16.875 12.7734H10V19.0234H16.875C18.5981 19.0234 20 17.6216 20 15.8984C20 14.1753 18.5981 12.7734 16.875 12.7734Z"
                fill="#DFDAE0"
              />
              <path
                d="M11.9531 16.875C12.4925 16.875 12.9297 16.4378 12.9297 15.8984C12.9297 15.3591 12.4925 14.9219 11.9531 14.9219C11.4138 14.9219 10.9766 15.3591 10.9766 15.8984C10.9766 16.4378 11.4138 16.875 11.9531 16.875Z"
                fill="#5E5F69"
              />
              <path
                d="M15.8594 16.875C16.3987 16.875 16.8359 16.4378 16.8359 15.8984C16.8359 15.3591 16.3987 14.9219 15.8594 14.9219C15.32 14.9219 14.8828 15.3591 14.8828 15.8984C14.8828 16.4378 15.32 16.875 15.8594 16.875Z"
                fill="#5E5F69"
              />
              <path
                d="M10.3906 6.91406H10V8.86719C10.3236 8.86719 10.5859 8.60484 10.5859 8.28125V8.05188C10.8134 7.97137 10.9766 7.755 10.9766 7.5C10.9766 7.17641 10.7142 6.91406 10.3906 6.91406Z"
                fill="#E50000"
              />
            </svg>
            <span>Set PIN</span>
          </li>
          <li @click="selected = 'app-block-card'">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.9375 7.8125H2.1875C1.85598 7.8125 1.53804 7.9442 1.30362 8.17862C1.0692 8.41304 0.9375 8.73098 0.9375 9.0625V17.8125C0.9375 18.144 1.0692 18.462 1.30362 18.6964C1.53804 18.9308 1.85598 19.0625 2.1875 19.0625H15.9375C16.269 19.0625 16.587 18.9308 16.8214 18.6964C17.0558 18.462 17.1875 18.144 17.1875 17.8125V9.0625C17.1875 8.73098 17.0558 8.41304 16.8214 8.17862C16.587 7.9442 16.269 7.8125 15.9375 7.8125ZM2.8125 10.9375V10C2.8125 9.91712 2.84542 9.83763 2.90403 9.77903C2.96263 9.72042 3.04212 9.6875 3.125 9.6875H6.25C6.33288 9.6875 6.41237 9.72042 6.47097 9.77903C6.52958 9.83763 6.5625 9.91712 6.5625 10V12.5C6.5625 12.5829 6.52958 12.6624 6.47097 12.721C6.41237 12.7796 6.33288 12.8125 6.25 12.8125H3.125C3.04212 12.8125 2.96263 12.7796 2.90403 12.721C2.84542 12.6624 2.8125 12.5829 2.8125 12.5V10.9375ZM15.3125 17.1875C15.3125 17.3533 15.2467 17.5122 15.1294 17.6294C15.0122 17.7467 14.8533 17.8125 14.6875 17.8125H11.5625C11.3967 17.8125 11.2378 17.7467 11.1206 17.6294C11.0033 17.5122 10.9375 17.3533 10.9375 17.1875V14.0625C10.9375 13.8967 11.0033 13.7378 11.1206 13.6206C11.2378 13.5033 11.3967 13.4375 11.5625 13.4375H11.875V12.1875C11.875 11.856 12.0067 11.538 12.2411 11.3036C12.4755 11.0692 12.7935 10.9375 13.125 10.9375C13.4565 10.9375 13.7745 11.0692 14.0089 11.3036C14.2433 11.538 14.375 11.856 14.375 12.1875V13.4375H14.6875C14.8533 13.4375 15.0122 13.5033 15.1294 13.6206C15.2467 13.7378 15.3125 13.8967 15.3125 14.0625V17.1875Z"
                fill="#FFAEAE"
              />
              <path
                d="M2.8125 4.0625H19.0625V5.9375H2.8125V4.0625Z"
                fill="#FFAEAE"
              />
              <path
                d="M13.125 15C13.2976 15 13.4375 14.8601 13.4375 14.6875C13.4375 14.5149 13.2976 14.375 13.125 14.375C12.9524 14.375 12.8125 14.5149 12.8125 14.6875C12.8125 14.8601 12.9524 15 13.125 15Z"
                fill="#FFAEAE"
              />
              <path
                d="M14.375 12.1875C14.375 11.856 14.2433 11.538 14.0089 11.3036C13.7745 11.0692 13.4565 10.9375 13.125 10.9375C12.7935 10.9375 12.4755 11.0692 12.2411 11.3036C12.0067 11.538 11.875 11.856 11.875 12.1875V13.4375H14.375V12.1875Z"
                fill="#FFAEAE"
              />
              <path
                d="M17.8125 1.875H4.0625C3.64825 1.8755 3.25111 2.04028 2.95819 2.33319C2.66528 2.62611 2.5005 3.02325 2.5 3.4375V7.5H2.1875C1.77325 7.5005 1.37611 7.66528 1.08319 7.95819C0.790276 8.25111 0.625496 8.64825 0.625 9.0625V17.8125C0.625496 18.2267 0.790276 18.6239 1.08319 18.9168C1.37611 19.2097 1.77325 19.3745 2.1875 19.375H15.9375C16.3517 19.3745 16.7489 19.2097 17.0418 18.9168C17.3347 18.6239 17.4995 18.2267 17.5 17.8125V13.75H17.8125C18.2267 13.7495 18.6239 13.5847 18.9168 13.2918C19.2097 12.9989 19.3745 12.6017 19.375 12.1875V3.4375C19.3745 3.02325 19.2097 2.62611 18.9168 2.33319C18.6239 2.04028 18.2267 1.8755 17.8125 1.875ZM3.125 4.375H18.75V5.625H3.125V4.375ZM16.875 17.8125C16.875 18.0611 16.7762 18.2996 16.6004 18.4754C16.4246 18.6512 16.1861 18.75 15.9375 18.75H2.1875C1.93886 18.75 1.7004 18.6512 1.52459 18.4754C1.34877 18.2996 1.25 18.0611 1.25 17.8125V9.0625C1.25 8.81386 1.34877 8.5754 1.52459 8.39959C1.7004 8.22377 1.93886 8.125 2.1875 8.125H15.9375C16.1861 8.125 16.4246 8.22377 16.6004 8.39959C16.7762 8.5754 16.875 8.81386 16.875 9.0625V17.8125ZM18.75 12.1875C18.75 12.4361 18.6512 12.6746 18.4754 12.8504C18.2996 13.0262 18.0611 13.125 17.8125 13.125H17.5V9.0625C17.4995 8.64825 17.3347 8.25111 17.0418 7.95819C16.7489 7.66528 16.3517 7.5005 15.9375 7.5H3.125V6.25H18.75V12.1875ZM3.125 3.75V3.4375C3.125 3.18886 3.22377 2.9504 3.39959 2.77459C3.5754 2.59877 3.81386 2.5 4.0625 2.5H17.8125C18.0611 2.5 18.2996 2.59877 18.4754 2.77459C18.6512 2.9504 18.75 3.18886 18.75 3.4375V3.75H3.125Z"
                fill="#FE4F4F"
              />
              <path
                d="M14.6875 13.125V12.1875C14.6875 11.7731 14.5229 11.3757 14.2299 11.0826C13.9368 10.7896 13.5394 10.625 13.125 10.625C12.7106 10.625 12.3132 10.7896 12.0201 11.0826C11.7271 11.3757 11.5625 11.7731 11.5625 12.1875V13.125C11.3139 13.125 11.0754 13.2238 10.8996 13.3996C10.7238 13.5754 10.625 13.8139 10.625 14.0625V17.1875C10.625 17.4361 10.7238 17.6746 10.8996 17.8504C11.0754 18.0262 11.3139 18.125 11.5625 18.125H14.6875C14.9361 18.125 15.1746 18.0262 15.3504 17.8504C15.5262 17.6746 15.625 17.4361 15.625 17.1875V14.0625C15.625 13.8139 15.5262 13.5754 15.3504 13.3996C15.1746 13.2238 14.9361 13.125 14.6875 13.125ZM12.1875 12.1875C12.1875 11.9389 12.2863 11.7004 12.4621 11.5246C12.6379 11.3488 12.8764 11.25 13.125 11.25C13.3736 11.25 13.6121 11.3488 13.7879 11.5246C13.9637 11.7004 14.0625 11.9389 14.0625 12.1875V13.125H12.1875V12.1875ZM15 17.1875C15 17.2704 14.9671 17.3499 14.9085 17.4085C14.8499 17.4671 14.7704 17.5 14.6875 17.5H11.5625C11.4796 17.5 11.4001 17.4671 11.3415 17.4085C11.2829 17.3499 11.25 17.2704 11.25 17.1875V14.0625C11.25 13.9796 11.2829 13.9001 11.3415 13.8415C11.4001 13.7829 11.4796 13.75 11.5625 13.75H14.6875C14.7704 13.75 14.8499 13.7829 14.9085 13.8415C14.9671 13.9001 15 13.9796 15 14.0625V17.1875Z"
                fill="#FE4F4F"
              />
              <path
                d="M13.75 14.6877C13.7505 14.5779 13.7221 14.4698 13.6676 14.3745C13.6131 14.2791 13.5345 14.1998 13.4395 14.1445C13.3446 14.0892 13.2368 14.0599 13.127 14.0596C13.0171 14.0592 12.9091 14.0878 12.8139 14.1425C12.7186 14.1972 12.6394 14.276 12.5843 14.371C12.5292 14.4661 12.5001 14.5739 12.5 14.6838C12.4999 14.7936 12.5287 14.9016 12.5835 14.9967C12.6384 15.0919 12.7174 15.1709 12.8125 15.2258V17.1877H13.75V16.5627H13.4375V16.2502H13.75V15.6252H13.4375V15.2258C13.5322 15.1714 13.6109 15.0931 13.6658 14.9986C13.7206 14.9041 13.7497 14.7969 13.75 14.6877Z"
                fill="#FE4F4F"
              />
              <path d="M2.5 15.625H3.75V16.25H2.5V15.625Z" fill="#FE4F4F" />
              <path
                d="M4.375 15.625H5.625V16.25H4.375V15.625Z"
                fill="#FE4F4F"
              />
              <path d="M6.25 15.625H7.5V16.25H6.25V15.625Z" fill="#FE4F4F" />
              <path
                d="M8.125 15.625H9.375V16.25H8.125V15.625Z"
                fill="#FE4F4F"
              />
              <path
                d="M6.25 9.375H3.125C2.95924 9.375 2.80027 9.44085 2.68306 9.55806C2.56585 9.67527 2.5 9.83424 2.5 10V12.5C2.5 12.6658 2.56585 12.8247 2.68306 12.9419C2.80027 13.0592 2.95924 13.125 3.125 13.125H6.25C6.41576 13.125 6.57473 13.0592 6.69194 12.9419C6.80915 12.8247 6.875 12.6658 6.875 12.5V10C6.875 9.83424 6.80915 9.67527 6.69194 9.55806C6.57473 9.44085 6.41576 9.375 6.25 9.375ZM6.25 11.25H5.625V10H6.25V11.25ZM5 10.625H4.375V10H5V10.625ZM3.75 10V10.625H3.125V10H3.75ZM3.125 11.25H5V12.5H3.125V11.25ZM5.625 12.5V11.875H6.25V12.5H5.625Z"
                fill="#FE4F4F"
              />
            </svg>
            <span>Block Card</span>
          </li>
          <li @click="selected = 'app-set-limits'">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.0312 19.8799C16.7074 19.8799 16.4453 19.6178 16.4453 19.2939V12.2236C16.4453 11.8998 16.7074 11.6377 17.0312 11.6377C17.3551 11.6377 17.6172 11.8998 17.6172 12.2236V19.2939C17.6172 19.6178 17.3551 19.8799 17.0312 19.8799Z"
                fill="#39326C"
              />
              <path
                d="M10 3.43457C9.0307 3.43457 8.24219 4.22309 8.24219 5.19238V6.36254L10 6.3634L11.7578 6.36426V5.19238C11.7578 4.22309 10.9693 3.43457 10 3.43457Z"
                fill="#EB5757"
              />
              <path
                d="M11.7578 5.19238C11.7578 4.22309 10.9693 3.43457 10 3.43457V6.3634L11.7578 6.36426V5.19238Z"
                fill="#E92222"
              />
              <path
                d="M19.4147 5.77832H14.8249L14.3477 6.93609L17.2913 11.8423L19.0826 12.8096H19.4147C19.7386 12.8096 20.0006 12.5475 20.0006 12.2237V6.3643C20.0006 6.04039 19.7386 5.77832 19.4147 5.77832Z"
                fill="#E2DFF4"
              />
              <path
                d="M14.8216 5.77832H11.3063L10.8438 6.96051L13.6992 11.7196L15.525 12.8096H19.0793L14.8216 5.77832Z"
                fill="#E92222"
              />
              <path
                d="M11.3094 5.77832H10.0005H7.79367L7.46094 7.17687L10.0005 11.4094L10.1817 11.7114L12.0125 12.8096H15.5281L11.3094 5.77832Z"
                fill="#F3F5F9"
              />
              <path
                d="M12.012 12.8096H15.5277L11.3089 5.77832H10V11.4094L10.1813 11.7114L12.012 12.8096Z"
                fill="#E2DFF4"
              />
              <path
                d="M9.9982 9.45625L7.79141 5.77832H4.27578L4.05078 7.35641L6.59285 11.593L8.49465 12.8096H9.9982H12.0102L9.9982 9.45625Z"
                fill="#EB5757"
              />
              <path d="M10 9.45605V12.8094H12.012L10 9.45605Z" fill="#E92222" />
              <path
                d="M4.27566 5.77832H0.721289L0.621094 7.56453L3.22172 11.8339L4.97898 12.8096H8.49453L4.27566 5.77832Z"
                fill="#F3F5F9"
              />
              <path
                d="M0.585938 5.77832C0.26207 5.77832 0 6.04039 0 6.36426V12.2236C0 12.5475 0.26207 12.8096 0.585938 12.8096H4.9809L0.723203 5.77832H0.585938Z"
                fill="#EB5757"
              />
              <path
                d="M10.9109 2.38258C10.5984 2.29902 10.413 1.97746 10.4966 1.66504L10.7999 0.553474C10.8834 0.240466 11.2079 0.0556612 11.5174 0.139216C11.8299 0.222771 12.0152 0.544333 11.9317 0.856755L11.6284 1.96832C11.5446 2.28312 11.2206 2.46613 10.9109 2.38258Z"
                fill="#4F4F4F"
              />
              <path
                d="M8.3741 1.9685L8.07082 0.856939C7.98726 0.544518 8.17265 0.222955 8.48508 0.1394C8.79863 0.0552989 9.11965 0.24069 9.20261 0.553658L9.5059 1.66522C9.58945 1.97764 9.40406 2.29921 9.09164 2.38276C8.78215 2.46624 8.458 2.28354 8.3741 1.9685Z"
                fill="#BDBDBD"
              />
              <path
                d="M6.6838 3.29263L5.85524 2.46407C5.62638 2.23521 5.62638 1.86439 5.85524 1.63552C6.08411 1.40665 6.45493 1.40665 6.6838 1.63552L7.51236 2.46407C7.74122 2.69294 7.74122 3.06376 7.51236 3.29263C7.28345 3.52154 6.91267 3.52154 6.6838 3.29263Z"
                fill="#BDBDBD"
              />
              <path
                d="M12.4841 3.29263C12.2553 3.06376 12.2553 2.69294 12.4841 2.46407L13.3127 1.63552C13.5416 1.40665 13.9124 1.40665 14.1412 1.63552C14.3701 1.86439 14.3701 2.23521 14.1412 2.46407L13.3127 3.29263C13.0838 3.52154 12.7131 3.52154 12.4841 3.29263Z"
                fill="#4F4F4F"
              />
              <path
                d="M18.2031 19.8799H15.8594C15.5355 19.8799 15.2734 19.6178 15.2734 19.2939C15.2734 18.9701 15.5355 18.708 15.8594 18.708H18.2031C18.527 18.708 18.7891 18.9701 18.7891 19.2939C18.7891 19.6178 18.527 19.8799 18.2031 19.8799Z"
                fill="#39326C"
              />
              <path
                d="M6.51609 13.9814L5.89867 13.3955H4.7268H3.55492L2.9375 13.9814L2.15625 16.3252L3.55492 16.9111H4.7268H5.89867L7.29734 16.3252L6.51609 13.9814Z"
                fill="#4F4F4F"
              />
              <path
                d="M7.29711 16.3252L6.51586 13.9814L5.89844 13.3955H4.72656V16.9111H5.89844L7.29711 16.3252Z"
                fill="#333333"
              />
              <path
                d="M7.29805 16.3252H4.7275H2.15695L1.24219 19.1086H4.7275H8.21281L7.29805 16.3252Z"
                fill="#BDBDBD"
              />
              <path
                d="M6.51609 13.9814L5.86836 12.0382C5.78883 11.7991 5.56508 11.6377 5.31273 11.6377H4.7268H4.14086C3.88852 11.6377 3.66477 11.7991 3.58523 12.0382L2.9375 13.9814H4.7268H6.51609Z"
                fill="#BDBDBD"
              />
              <path
                d="M7.29711 16.3252H4.72656V19.1086H8.21187L7.29711 16.3252Z"
                fill="#4F4F4F"
              />
              <path
                d="M5.86813 12.0382C5.78859 11.7991 5.56484 11.6377 5.3125 11.6377H4.72656V13.9814H6.51586L5.86813 12.0382Z"
                fill="#4F4F4F"
              />
              <path
                d="M8.82812 18.708H4.72656H0.585938C0.257773 18.708 0 18.9658 0 19.2939C0 19.622 0.257773 19.8799 0.585938 19.8799H4.72656H8.82812C9.15629 19.8799 9.41406 19.622 9.41406 19.2939C9.41406 18.9658 9.15629 18.708 8.82812 18.708Z"
                fill="#51489A"
              />
              <path
                d="M9.41406 19.2939C9.41406 19.622 9.15629 19.8799 8.82812 19.8799H4.72656V18.708H8.82812C9.15629 18.708 9.41406 18.9658 9.41406 19.2939Z"
                fill="#39326C"
              />
            </svg>
            <span>Set Limits</span>
          </li>
        </ul>
      </div>
    </div>

    <div class="actions__right">
      <transition name="slide-fade">
        <!-- <keep-alive> -->
        <component
          :is="selected"
          @switchRightComponent="changeSelected"
        ></component>
        <!-- </keep-alive> -->
      </transition>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ActionLanding from "@/components/Cards/Actions/ActionLanding";
import BlockCard from "@/components/Cards/Actions/BlockCard";
import SetLimits from "@/components/Cards/Actions/SetLimits";
import SetPin from "@/components/Cards/Actions/SetPin";
export default {
  computed: mapState(["userDetailsTwo", "user", "cardDetail"]),
  components: {
    "app-action-landing": ActionLanding,
    "app-block-card": BlockCard,
    "app-set-limits": SetLimits,
    "app-set-pin": SetPin
  },
  data() {
    return {
      selected: "app-action-landing"
    };
  },
  methods: {
    changeSelected() {
      this.selected = "app-action-landing";
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_cardActions.scss";
</style>
